/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import BunkerPrice from 'layouts/bunkerPrice';
import CompanyList from 'layouts/companyList';
import Dashboard from 'layouts/dashboard';
import MenuList from 'layouts/menuList';
import Packages from 'layouts/packages/index';
import PaymentList from 'layouts/paymentList';
import Roles from 'layouts/roles';
import SubscriptionList from 'layouts/subscriptionList';
import Users from 'layouts/users';

// @mui icons
import Icon from '@mui/material/Icon';
import CargoList from 'layouts/cargo-list';
import ContactList from 'layouts/ContactList/ContactList';
import CustomerQuery from 'layouts/customerQuery';
import FreightRateList from 'layouts/frieght-rate';
import HireRateList from 'layouts/hire-rate';
import OpenAiHitList from 'layouts/openAiHitList/openAiHitList';
import RequestDemoList from 'layouts/requestDemoList';
import Resubscribe from 'layouts/resubscribe';
import ThirdParty from 'layouts/third-part-api';
import TonnageList from 'layouts/tonnage-list';
import VesselList from 'layouts/vessel-list';
import NewsSectionList from 'layouts/newsSectionList';
import ImportOption from 'layouts/EmailImport';
import ImportEmailList from 'layouts/importEmailList';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatsAppUserTable from 'layouts/whatsAppManagement';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PushNotification from 'layouts/pushNotification/PushNotification';
import LoginSessions from './layouts/loginSessions';


const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Request Demo',
    key: 'request-demo-list',
    icon: <Icon fontSize="small">menu</Icon>,
    route: '/request-demo-list',
    component: <RequestDemoList />,
  },
  {
    type: 'collapse',
    name: 'Company',
    key: 'companyList',
    icon: <Icon fontSize="small">group</Icon>,
    route: '/companyList',
    component: <CompanyList />,
  },

  {
    type: 'collapse',
    name: 'Users',
    key: 'users',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/users',
    component: <Users />,
  },
  {
    type: 'collapse',
    name: 'WhatsApp Management',
    key: 'manage-whatsapp',
    icon: <WhatsAppIcon fontSize="small" />,
    route: '/manage-whatsapp',
    component: <WhatsAppUserTable />,
  },
  {
    type: 'collapse',
    name: 'Push Notifications',
    key: 'push-notifications',
    icon: <NotificationsIcon fontSize="small" />,
    route: '/push-notification',
    component: <PushNotification />,
  },
  {
    type: 'collapse',
    name: 'Login Session',
    key: 'Login-Session',
    icon: <NotificationsIcon fontSize="small" />,
    route: '/login-session',
    component: <LoginSessions />,
  },
  {
    type: 'collapse',
    name: 'Imported Emails',
    key: 'importEmails',
    icon: <Icon fontSize="small">email</Icon>,
    route: '/imported-emails-list',
    component: <ImportEmailList />,
  },
  {
    type: 'collapse',
    name: 'Subscription List',
    key: 'subscriptionList',
    icon: <Icon fontSize="small">check</Icon>,
    route: '/subscriptionList',
    component: <SubscriptionList />,
  },
  {
    type: 'collapse',
    name: 'Cargo List',
    key: 'cargo-list',
    icon: <Icon fontSize="small">water</Icon>,
    route: '/cargo-list',
    component: <CargoList />,
  },
  {
    type: 'collapse',
    name: 'Tonnage List',
    key: 'tonnage-list',
    icon: <Icon fontSize="small">water</Icon>,
    route: '/tonnage-list',
    component: <TonnageList />,
  },

  {
    type: 'collapse',
    name: "Third Party API's List",
    key: 'third-party-api-list',
    icon: <Icon fontSize="small">api</Icon>,
    route: '/third-party-api-list',
    component: <ThirdParty />,
  },
  {
    type: 'collapse',
    name: "Email Import Options",
    key: 'email-import-options',
    icon: <Icon fontSize="small">mail</Icon>,
    route: '/email-import-options',
    component: <ImportOption />
  },
  {
    type: 'collapse',
    name: "Open AI Hit List",
    key: 'open-ai-hit-list',
    icon: <Icon fontSize="small">menu</Icon>,
    route: '/open-ai-hit-list',
    component: <OpenAiHitList />
  },
  // {
  //   type: 'collapse',
  //   name: "Refresh Outlook",
  //   key: 'refresh-outlook-subscription',
  //   icon: <Icon fontSize="small">refresh</Icon>,
  //   route: '/refresh-outlook-subscription',
  //   component: <Resubscribe />,
  // },
  {
    type: 'collapse',
    name: "Hire Rate List",
    key: 'hire-rate-list',
    icon: <Icon fontSize="small">menu</Icon>,
    route: '/hire-rate-list',
    component: <HireRateList />,
  },
  {
    type: 'collapse',
    name: "Freight Rate List",
    key: 'freight-rate-list',
    icon: <Icon fontSize="small">menu</Icon>,
    route: '/freight-rate-list',
    component: <FreightRateList />,
  },
  {
    type: 'collapse',
    name: "Vessel List",
    key: 'vessel-list',
    icon: <Icon fontSize="small">menu</Icon>,
    route: '/vessel-list',
    component: <VesselList />,
  },
  {
    type: 'collapse',
    name: 'Contact List',
    key: 'contact-list',
    icon: <Icon fontSize="small">call</Icon>,
    route: '/contact-list',
    component: <ContactList />,
  },
  {
    type: 'collapse',
    name: 'Packages',
    key: 'packages',
    icon: <Icon fontSize="small">list</Icon>,
    route: '/packages',
    component: <Packages />,
  },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  {
    type: 'collapse',
    name: 'Roles',
    key: 'roles',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/roles',
    component: <Roles />,
  },
  {
    type: 'collapse',
    name: 'Bunker Prices',
    key: 'bunker_price',
    icon: <Icon fontSize="small">money</Icon>,
    route: '/bunker_price',
    component: <BunkerPrice />,
  },
  {
    type: 'collapse',
    name: 'Menu List',
    key: 'menu-list',
    icon: <Icon fontSize="small">menu</Icon>,
    route: '/menu-list',
    component: <MenuList />,
  },
  {
    type: 'collapse',
    name: "Customer's Query",
    key: 'customer-query-list',
    icon: <Icon fontSize="small">mail</Icon>,
    route: '/customer-query-list',
    component: <CustomerQuery />,
  },
  {
    type: 'collapse',
    name: 'Payment List',
    key: 'payment-list',
    icon: <Icon fontSize="small">payment</Icon>,
    route: '/payment-list',
    component: <PaymentList />,
  },

  {
    type: 'collapse',
    name: 'News Section',
    key: 'news-section-list',
    icon: <Icon fontSize="small">newspaper</Icon>,
    route: '/news-section-list',
    component: <NewsSectionList />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
