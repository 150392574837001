import { Button, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { openNotificationWithIcon } from 'shared';
import { URL_WITH_VERSION } from 'shared';

const AddNotification = (props) => {
  const { type, editData } = props;
  const [data, setData] = useState({
    notification_name: type == 'edit' ? editData?.notification_name : '',
    html_content: type == 'edit' ? editData?.html_content : '',
  });

  const handleAddNotification = async () => {
    if (!data.notification_name || !data.html_content) {
      openNotificationWithIcon('info', 'Both fields are required.');
      return;
    }
    try {
      const payload = {
        ...data,
        id: editData?.id,
      };
      const requestOptions = {
        method: type == 'add' ? 'POST' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(type == 'add' ? data : payload),
        redirect: 'follow',
      };

      const response = await fetch(
        `${URL_WITH_VERSION}accounts/pushNotifications`,
        requestOptions,
      );

      const responseData = await response.json();
      if (responseData?.id || responseData?.data) {
        if (type == 'edit') {
          openNotificationWithIcon(
            'success',
            'Notification updated successfully',
          );
        } else {
          openNotificationWithIcon(
            'success',
            'Notification added successfully',
          );
        }
        props.close();
        props.getTableData();
      } else {
        console.log('some error occured');
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div>
        <label
          htmlFor="title"
          style={{ display: 'block', marginBottom: '8px' }}
        >
          Notification Title:
        </label>
        <Input
          value={data?.notification_name}
          onChange={(e) =>
            setData((prev) => ({ ...prev, notification_name: e.target.value }))
          }
          id="title"
          placeholder="Enter notification title"
        />
      </div>

      <div style={{ marginTop: '30px' }}>
        <label
          htmlFor="message"
          style={{ display: 'block', marginBottom: '8px' }}
        >
          Notification Message:
        </label>
        <Input
          value={data?.html_content}
          onChange={(e) =>
            setData((prev) => ({ ...prev, html_content: e.target.value }))
          }
          id="message"
          placeholder="Enter notification message"
        />
      </div>
      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        {type == 'add' ? (
          <Button onClick={handleAddNotification}>Add</Button>
        ) : (
          <Button onClick={handleAddNotification}>Update</Button>
        )}
      </div>
    </>
  );
};

AddNotification.propTypes = {
  close: PropTypes.func.isRequired, // Validate 'close' as a required function
  getTableData: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  type: PropTypes.func.isRequired,
};

export default AddNotification;
