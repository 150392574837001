import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, CircularProgress, Grid, IconButton } from '@mui/material';
import { Button, Modal, Popconfirm } from 'antd';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { URL_WITH_VERSION } from 'shared';
import AddNotification from './AddNotification';
import CompanyList from './CompanyList';
import { openNotificationWithIcon } from 'shared';

const PushNotification = () => {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [openAddNot, setOpenAddNot] = useState(false);
  const [type, setType] = useState('add');
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [editData, setEditData] = useState({});
  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    handleSearch(searchText);
  }, [tableData, searchText]);

  const handleSearch = (searchText) => {
    if (!searchText.trim()) {
      setFilteredData(tableData);
      return;
    }

    const filtered = tableData.filter((row) =>
      Object.values(row)
        .join(' ')
        .toLowerCase()
        .includes(searchText.toLowerCase()),
    );

    setFilteredData(filtered);
  };

  const columns = [
    {
      name: 'Serial Number',
      selector: (row, index) => index + 1, // Serial number based on row index
      sortable: false, // Serial numbers are not sortable
      center: true,
      width: '100px',
    },
    {
      name: 'Name',
      selector: (row) => row?.notification_name,
      sortable: true,
      center: true,
    },
    {
      name: 'Content',
      selector: (row) => row?.html_content,
      sortable: true,
      center: true,
    },
    {
      name: 'Created At',
      selector: (row) => moment(row?.created_at).format('DD-MM-YYYY hh:mm A'), // Format date and time
      sortable: true,
      center: true,
    },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <IconButton color="secondary" onClick={() => handleEdit(row)}>
            <EditOutlined />
          </IconButton>
          <Popconfirm
            title="Delete the notification"
            description="Are you sure to delete this notification?"
            onConfirm={() => confirm(row)}
            okText="Yes"
            cancelText="No"
            placement="top"
          >
            <IconButton color="primary">
              <DeleteOutlined />
            </IconButton>
          </Popconfirm>
        </div>
      ),
    },
    {
      name: 'Push',
      cell: (row) => (
        <div>
          <Button
            onClick={() => {
              setOpenCompanyModal(true);
              setEditData(row);
            }}
          >
            Push
          </Button>
        </div>
      ),
    },
  ];

  const handleEdit = (row) => {
    setType('edit');
    setEditData(row);
    setOpenAddNot(true);
  };
  const confirm = async (data) => {
    try {
      const requestOptions = {
        method: 'DELETE',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: data?.id }),
      };

      const response = await fetch(
        `${URL_WITH_VERSION}accounts/pushNotifications`,
        requestOptions,
      );

      const responseData = await response.json();
     
      if(responseData){
        openNotificationWithIcon("success","Deleted successfully")
        getTableData()
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTableData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const response = await fetch(
        `${URL_WITH_VERSION}accounts/pushNotifications`,
        requestOptions,
      );

      const responseData = await response.json();
      if (response) {
        setTableData(responseData?.data);
        setFilteredData(responseData?.data);
      } else {
        console.log('api failed');
      }
    } catch (error) {
      console.log(error);
    }
  };
  const close = () => {
    setOpenAddNot(false);
  };

  const close2 = () => {
    setOpenCompanyModal(false);
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <MDInput
                  label="Search here"
                  style={{
                    flex: 1,
                    marginRight: '20px',
                    minWidth: '200px',
                    maxWidth: '600px',
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <MDButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenAddNot(true);
                    setType('add');
                  }}
                >
                  Add Notification
                </MDButton>
              </div>
              <br />
              <br />
              <Card>
                <MDBox>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    // progressPending={pending}
                    progressComponent={<CircularProgress />}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
      {openAddNot ? (
        <Modal
          open={openAddNot}
          onCancel={() => setOpenAddNot(false)}
          footer={null}
          title={type == 'add' ? 'Add Notification' : 'Edit Notification'}
        >
          <AddNotification
            close={close}
            getTableData={getTableData}
            type={type}
            editData={editData}
          />
        </Modal>
      ) : null}
      {openCompanyModal ? (
        <Modal
          open={openCompanyModal}
          onCancel={() => setOpenCompanyModal(false)}
          footer={null}
          title="Select Company"
        >
          <CompanyList close2={close2} editData={editData} />
        </Modal>
      ) : null}
    </>
  );
};

export default PushNotification;
