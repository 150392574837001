import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDBox from '../../components/MDBox';
import { Card, CircularProgress, Grid } from '@mui/material';
import MDInput from '../../components/MDInput';
import MDButton from '../../components/MDButton';
import DataTable from 'react-data-table-component';
import React, { useEffect, useState } from 'react';
import { URL_WITH_VERSION } from '../../shared';
import { CSVLink, CSVDownload } from "react-csv";



function LoginSessions(props) {
  const [data,setData] = useState([]);
  const [filterData, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [DownlaodEnabled, setDownlaodEnabled] = useState(false);
  const [downloadbledata,setDowloadbledata] = useState([]);



  const columns = [
    {
      name: ' ID',
      selector: (row, index) => row.id, // Serial number based on row index
      sortable: false, // Serial numbers are not sortable
      center: true,
      width: '200px',
    },
   {
    name: 'Company Name',
    selector: (row, index) => row.company.company_name, // Serial number based on row index
    sortable: false, // Serial numbers are not sortable
    center: true,
    width: '200px',
  },
    {
      name: 'Email ID',
      selector: (row, index) => row.company.email, // Serial number based on row index
      sortable: false, // Serial numbers are not sortable
      center: true,
      width: '300px',
    },
    {
      name: 'Device Type',
      selector: (row, index) => row.app_type, // Serial number based on row index
      sortable: false, // Serial numbers are not sortable
      center: true,
      width: '300px',
    },
    {
      name: 'user Agent',
      selector: (row, index) => row.user_agent, // Serial number based on row index
      sortable: false, // Serial numbers are not sortable
      center: true,
      width: '200px',
    },
    {
      name: 'Login Time',
      selector: (row, index) => row.login_time, // Serial number based on row index
      sortable: false, // Serial numbers are not sortable
      center: true,
      width: '300px',
    },
    {
      name: 'Last Active',
      selector: (row, index) => row.last_activity, // Serial number based on row index
      sortable: false, // Serial numbers are not sortable
      center: true,
      width: '300px',
    },
    {
      name: 'User Active Time',
      selector: (row, index) => {

      }, // Serial number based on row index

      cell :(row,index) => {
        function getYoutubeLikeToDisplay(millisec) {
          var seconds = (millisec / 1000).toFixed(0);
          var minutes = Math.floor(seconds / 60);
          var hours = "";
          if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            hours = (hours >= 10) ? hours : "0" + hours;
            minutes = minutes - (hours * 60);
            minutes = (minutes >= 10) ? minutes : "0" + minutes;
          }

          seconds = Math.floor(seconds % 60);
          seconds = (seconds >= 10) ? seconds : "0" + seconds;
          if (hours != "") {
            return hours + ":" + minutes + ":" + seconds;
          }
          return minutes + ":" + seconds;
        }
        const loginTime = new Date(row.login_time);
        const lastActivity = new Date(row.last_activity);

        const activeTimeMs = lastActivity - loginTime;

        const activeTimeSeconds = activeTimeMs / 1000; // Convert to seconds

        console.log(`Active Time: ${activeTimeMs} ms (${activeTimeSeconds} seconds)`);
        return <p>{getYoutubeLikeToDisplay(activeTimeMs)}</p>
      },
      sortable: false, // Serial numbers are not sortable
      center: true,
      width: '300px',
    },


  ]
  useEffect(() => {
    async function fetchData() {
      const requestOptions  = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        })
      }
      const response = await fetch(
        `${URL_WITH_VERSION}analytic/get-login-session`,
        requestOptions,
      );
      if(response.status === 200) {
        let responseData = await response.json();
        setData(responseData?.data);
        setFilter(responseData?.data);

        setLoading(false);
      }
    }
  fetchData()
  },[])
  return <>
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <MDInput
                label="Search here"
                style={{
                  flex: 1,
                  marginRight: '20px',
                  minWidth: '200px',
                  maxWidth: '600px',
                }}
                onChange={(e) =>{
                  let value  =  e.target.value
                  console.log("dataaaa",data)
                  // const filtered = data.filter((row) =>
                  //   Object.values(row)
                  //     .join(' ')
                  //     .toLowerCase()
                  //     .includes(value.toLowerCase()),
                  // );
                  const filtered = data.filter((row) => {
                    const { company } = row;
                    if (!company) return false;
                    const searchText = value.toLowerCase();
                    return (
                      company.company_name.toLowerCase().includes(searchText) ||
                      company.email.toLowerCase().includes(searchText)||
                        row?.app_type.toLowerCase().includes(searchText)

                    );
                  });

                  console.log("filtered",filtered)
                  setFilter(filtered);

                }}
              />
              <MDButton
                // variant="contained"
                color="primary"
                onClick={() => {
                  let result  =  []
                  for(let i = 0; i<filterData.length; i++) {
                    let obj  = JSON.stringify(filterData[i]);
                    let resultobj  =   JSON.parse(obj)
                    resultobj.company = filterData[i].company.company_name;


                    result.push(resultobj)
                  }
                  setDowloadbledata(result);
                  setDownlaodEnabled(true);

                }}
              >
                Download Filtered Data
              </MDButton>
              {  DownlaodEnabled ?   <> <CSVDownload data={downloadbledata} target="_blank" />;
              </> :<></>}

            </div>
            <br />
            <br />
            <Card>
              <MDBox>
                <DataTable
                  columns={columns}
                  data={filterData}
                  pagination
                  progressPending={loading}
                  progressComponent={<CircularProgress />}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>

  </>
}
export default LoginSessions;