import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Card, Typography, Button } from "@mui/material";

function SuccessMessageCards({ data = [], setData }) {
  const [visible, setVisible] = useState(true);
  const [counter, setCounter] = useState(10); 

  useEffect(() => {
    if (data.length > 0 && visible) {
      const interval = setInterval(() => {
        setCounter((prev) => {
          if (prev === 1) {
            setVisible(false);
            setData([]);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval); 
    }
  }, [data, visible, setData]);

  // const handleStop = () => {
  //   setVisible(true); 
  // };

  const handleClose = () => {
    setVisible(false);
    setData([]); 
  };

  if (data.length === 0 || !visible) return null;

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
      <Card sx={{ p: 3, textAlign: "center", boxShadow: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Message Status
        </Typography>
        {data.map((msgDetails, index) => (
          <div key={index}>
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              <span style={msgDetails.message === 'Error' ? { color: "red" } : { color: "green" }}>{msgDetails.message === 'Error' ? "Message failed to send." : msgDetails.message}</span>: <span>{msgDetails.number}  </span> 
              
                 ID:
              <span> {msgDetails?.id}</span>
            </Typography>
          </div>
        ))}
        <Typography variant="caption" sx={{ mt: 2, display: "block", color: "red" }}>
          Closing in {counter} seconds...
        </Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          {/* <Button variant="outlined" color="primary" onClick={handleStop} size="small">
            Stop
          </Button> */}
          <Button  style={{color:'#fff', backgroundColor:'#000'}} onClick={handleClose} size="small">
            Close
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

SuccessMessageCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      response: PropTypes.shape({
        contacts: PropTypes.arrayOf(
          PropTypes.shape({
            input: PropTypes.string,
            wa_id: PropTypes.string,
          })
        ),
        messages: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
          })
        ),
        messaging_product: PropTypes.string,
      }),
    })
  ),
  setData: PropTypes.func.isRequired, 
};

export default SuccessMessageCards;
