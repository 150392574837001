import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { openNotificationWithIcon } from 'shared';
import { URL_WITH_VERSION } from 'shared';

const CompanyList = (props) => {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false); // To track the state of the Select All checkbox

  useEffect(() => {
    getlist();
  }, []);

  const getlist = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      const response = await fetch(
        `${URL_WITH_VERSION}accounts/company-details`,
        requestOptions,
      );

      const responseData = await response.json();

      if (Array.isArray(responseData)) {
        setCompanies(responseData); // Assuming responseData is an array of companies
      } else {
        console.error('Unexpected response format:', responseData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (companyName) => {
    setSelectedCompanies((prevSelected) =>
      prevSelected.includes(companyName)
        ? prevSelected.filter((name) => name !== companyName) // Remove if already selected
        : [...prevSelected, companyName], // Add if not selected
    );
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      // Unselect all companies
      setSelectedCompanies([]);
    } else {
      // Select all filtered companies
      setSelectedCompanies(filteredCompanies.map((company) => company.company_name));
    }
    setIsAllSelected(!isAllSelected);
  };

  const filteredCompanies = companies.filter((company) =>
    company?.company_name?.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handlePushNotification = async () => {
    if (selectedCompanies.length === 0) {
        openNotificationWithIcon("info","Please select any company")
        return;}
    try {
      const payload = {
        comanyNames: selectedCompanies,
        notification_id: props?.editData?.id,
      };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        redirect: 'follow',
      };

      const response = await fetch(
        `${URL_WITH_VERSION}accounts/pushNotificationsToCompany`,
        requestOptions,
      );

      const responseData = await response.json();
      if (responseData?.msg === "sent") {
        openNotificationWithIcon("success", "Notifications sent");
        props.close2();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Update the Select All state whenever filtered companies change
  useEffect(() => {
    setIsAllSelected(
      filteredCompanies.length > 0 &&
        filteredCompanies.every((company) =>
          selectedCompanies.includes(company.company_name),
        ),
    );
  }, [filteredCompanies, selectedCompanies]);

  return (
    <div style={{ padding: '20px', maxHeight: '500px', overflowY: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Company List</h2>
        <Button onClick={handlePushNotification}>Push</Button>
      </div>
      <h6 style={{marginBottom:"10px"}}>{props?.editData?.notification_name}</h6>
      <input
        type="text"
        placeholder="Search companies..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          width: '100%',
          padding: '10px',
          marginBottom: '20px',
          fontSize: '16px',
        }}
      />
      <div>
        <input
          type="checkbox"
          id="select-all"
          onChange={handleSelectAllChange}
          checked={isAllSelected}
        />
        <label htmlFor="select-all" style={{ marginLeft: '8px', fontWeight: 'bold', marginBottom:"10px" }}>
          Select All
        </label>
      </div>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {filteredCompanies.map((company) => (
          <li
            key={company.company_name} // Use company_name as the unique key
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <input
              type="checkbox"
              id={`company-${company.company_name}`}
              value={company.company_name}
              onChange={() => handleCheckboxChange(company.company_name)}
              checked={selectedCompanies.includes(company.company_name)} // Check based on company name
            />
            <label
              htmlFor={`company-${company.company_name}`}
              style={{ marginLeft: '8px' }}
            >
              {company.company_name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

CompanyList.propTypes = {
  editData: PropTypes.object.isRequired,
  close2: PropTypes.func.isRequired,
};

export default CompanyList;
